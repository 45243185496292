import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import FieldCheckboxGroup from '../../FieldCheckboxGroup/FieldCheckboxGroup';
import { ADD_ON_ICON_OPTIONS } from '../../../util/listing';
import { convertToMoney, formatMoney } from '../../../util/currency';
import { useIntl } from 'react-intl';
import { ADD_ONS_OPTIONS } from '../../../constants/listing';
import { capitalizeWord } from '../../../util/string';

import css from './AddOnsField.module.css';

const AddOnsField = props => {
  const { className, addOns, currency, formAddOns } = props;

  const intl = useIntl();

  const isOptionInCategoryDisabled = useCallback(
    (optionCategory, optionId) =>
      formAddOns?.some(selectedAddon => {
        const [category, _id] = selectedAddon.split('.');

        // If its the same category disable the field,
        // but don't disable if its the checked field
        return category === optionCategory && _id !== optionId;
      }),
    [formAddOns]
  );

  const addOnsOptions = useMemo(
    () =>
      addOns?.reduce((addOnsByCategory, addOn, i) => {
        const { _id, title, category, iconName, price, extraTravelPrice } = addOn;

        const icon = ADD_ON_ICON_OPTIONS.find(opt => opt.value === iconName)?.image;

        const option = {
          key: `${category}.${_id}`,
          disabled: isOptionInCategoryDisabled(category, _id),
          label: (
            <span className={css.addOn}>
              <img src={icon} alt="" className={css.iconWrapper} />

              <span className={css.addOnTitle}>{title}</span>

              <span className={css.price}>
                {price?.amount != null && formatMoney(intl, convertToMoney(price, currency))}
                <br />

                {extraTravelPrice ? (
                  <span>
                    {'+ '}
                    {price?.amount != null &&
                      formatMoney(intl, convertToMoney(extraTravelPrice, currency))}
                    {' / ' + intl.formatMessage({ id: 'General.perMile' }).toLowerCase()}
                  </span>
                ) : null}
              </span>
            </span>
          ),
        };

        addOnsByCategory[category] = [...(addOnsByCategory[category] || []), option];

        return addOnsByCategory;
      }, {}),
    [addOns, currency, intl, isOptionInCategoryDisabled]
  );

  if (!addOns?.length) return null;

  return (
    <div className={classNames(css.root, className)}>
      <label htmlFor="formAddOns">
        {intl.formatMessage({ id: 'EditListingAddOnsPanel.createListingTitle' })}
      </label>

      <div className={css.addOns}>
        {ADD_ONS_OPTIONS.map(({ value: category }) =>
          addOnsOptions[category]?.length ? (
            <React.Fragment key={category}>
              <div className={css.addOnCategory}>
                {intl.formatMessage({ id: `General.category${capitalizeWord(category)}` })}
              </div>

              <FieldCheckboxGroup
                name="formAddOns"
                id="formAddOns"
                checkboxClassName={css.addOnCheckbox}
                options={addOnsOptions[category]}
              />
            </React.Fragment>
          ) : null
        ).filter(Boolean)}
      </div>
    </div>
  );
};

export default AddOnsField;
