import { storableError } from '../../util/errors';
import { denormalizeAssetData } from '../../util/data';
import listings from '../../api/listings';
import { types as sdkTypes } from '../../util/sdkLoader';

// ================ Action types ================ //

export const QUERY_POPULAR_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_POPULAR_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_POPULAR_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

const GET_BLOGS_REQUEST = 'app/LandingPage/GET_BLOGS_REQUEST';
const GET_BLOGS_SUCCESS = 'app/LandingPage/GET_BLOGS_SUCCESS';
const GET_BLOGS_ERROR = 'app/LandingPage/GET_BLOGS_ERROR';

const { Money, UUID } = sdkTypes;

// ================ Reducer ================ //

const initialState = {
  queryPopularListingsInProgress: false,
  queryPopularListingsError: null,
  popularListings: [],

  blogs: [],
  getBlogsInProgress: false,
  getBlogsError: null,
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case QUERY_POPULAR_LISTINGS_REQUEST:
      return {
        ...state,
        queryPopularListingsInProgress: true,
        queryPopularListingsError: null,
      };
    case QUERY_POPULAR_LISTINGS_SUCCESS:
      return {
        ...state,
        queryPopularListingsInProgress: false,
        popularListings: payload.listings,
      };
    case QUERY_POPULAR_LISTINGS_ERROR:
      return {
        ...state,
        queryPopularListingsInProgress: false,
        queryPopularListingsError: payload,
      };

    case GET_BLOGS_REQUEST:
      return { ...state, getBlogsInProgress: true, getBlogsError: null };
    case GET_BLOGS_SUCCESS:
      return { ...state, getBlogsInProgress: false, blogs: payload.blogs };
    case GET_BLOGS_ERROR:
      return { ...state, getBlogsInProgress: false, getBlogsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryPopularListingsRequest = () => ({
  type: QUERY_POPULAR_LISTINGS_REQUEST,
});

export const queryPopularListingsSuccess = listings => ({
  type: QUERY_POPULAR_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryPopularListingsError = e => ({
  type: QUERY_POPULAR_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const getBlogsRequest = () => ({
  type: GET_BLOGS_REQUEST,
});

const getBlogsSuccess = blogs => ({
  type: GET_BLOGS_SUCCESS,
  payload: { blogs },
});

const getBlogsError = e => ({
  type: GET_BLOGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryPopularListings = (params, config) => (dispatch, getState, sdk) => {
  dispatch(queryPopularListingsRequest());

  const newParams = {
    limit: 10,
    page: 1,
    ...params,
  };

  listings
    .getListings(newParams)
    .then(response => {
      dispatch(
        queryPopularListingsSuccess(
          response?.data?.items?.map(item => ({
            ...item,
            id: new UUID(item?._id),
            attributes: {
              ...item?.attributes,
              price: new Money(item?.attributes?.price?.amount, item?.attributes?.price?.currency),
            },
          }))
        )
      );
      return response;
    })
    .catch(e => {
      dispatch(queryPopularListingsError(storableError(e)));
      throw e;
    });
};

const getContentForAssets = async (sdk, assetPaths) => {
  try {
    const res = await sdk.assetsByAlias({
      paths: assetPaths,
      alias: 'latest',
    });

    return res.data.data.map(asset => {
      const data = denormalizeAssetData({ data: asset.attributes, included: res.data.included });

      return data;
    });
  } catch (error) {
    throw error;
  }
};

export const getBlogs = () => (dispatch, getState, sdk) => {
  dispatch(getBlogsRequest());

  return sdk.sitemapData
    .queryAssets({ pathPrefix: '/content/pages/' })
    .then(async response => {
      const assets = response.data.data || [];

      const clientBlogAssets = assets.filter(asset =>
        asset.attributes?.assetPath?.startsWith('/content/pages/blogs-client-')
      );

      let clientBlogs = [];

      if (clientBlogAssets?.length) {
        clientBlogs = await getContentForAssets(
          sdk,
          clientBlogAssets.slice(0, 3).map(asset => asset.attributes?.assetPath)
        );
      }

      dispatch(getBlogsSuccess(clientBlogs));
    })
    .catch(e => {
      dispatch(getBlogsError(e));
      throw e;
    });
};

export const loadData = (params, query, config) => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(getBlogs()),
    dispatch(queryPopularListings({ sort: '-rate' }, config)),
  ]);
};
