import React, { useMemo, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import {
  Avatar,
  IconArtist,
  IconCalendar,
  IconLocation,
  InlineTextButton,
  ReviewRating,
  UserDisplayName,
} from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';
import { EVENT_TYPE_OPTIONS } from '../../constants/listing';

const MAX_REVIEWS_SHOWN_INITIALLY = 2;

const getInitials = fullName => {
  const names = fullName.split(' ');
  return names.map(name => name[0]?.toUpperCase()).join('');
};

const Review = props => {
  const { review, intl, isLast } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { day: 'numeric', month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <div className={css.reviewHeader}>
        <div className={css.withoutDate}>
          <Avatar className={css.avatar} user={review.author} />

          <div className={css.ratingAndNameWrapper}>
            <UserDisplayName user={review.author} intl={intl} className={css.author} />

            <ReviewRating
              rating={review.attributes?.rating}
              className={css.mobileReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </div>
        </div>
        <span className={css.date}> {dateString}</span>
      </div>

      <div>
        <p className={css.reviewContent}>"{review?.attributes?.content}"</p>
        {!isLast && <hr className={css.divider} />}
      </div>
    </div>
  );
};

const ExternalReview = props => {
  const { review, intl, isLast } = props;
  const { clientName, ratedExperience, eventDate, eventLocation, description, eventType } =
    review || {};

  const eventDateFormatted = new Date(eventDate)?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className={css.review}>
      <div className={css.reviewHeader}>
        <div className={css.withoutDate}>
          <Avatar className={css.avatar} displayName={getInitials(clientName)} />
          <div className={css.ratingAndNameWrapper}>
            <span className={css.author}>{clientName}</span>
            <ReviewRating
              rating={ratedExperience}
              className={css.mobileReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </div>
        </div>
      </div>
      <div className={css.infoContainer}>
        <div className={css.info}>
          <IconArtist />
          {intl.formatMessage({
            id:
              EVENT_TYPE_OPTIONS.find(option => option.value === eventType)?.label ||
              'General.other',
          })}
        </div>
        <div className={css.info}>
          <IconCalendar />
          {eventDateFormatted}
        </div>
        <div className={css.info}>
          <IconLocation />
          {eventLocation}
        </div>
      </div>
      <div>
        <p className={css.reviewContent}>"{description}"</p>
        {!isLast && <hr className={css.divider} />}
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl } = props;
  const classes = classNames(rootClassName, className);

  const [showAllReviews, setShowAllReviews] = useState(false);

  const displayedReviews = useMemo(
    () => (showAllReviews ? reviews : reviews?.slice(0, MAX_REVIEWS_SHOWN_INITIALLY)),
    [reviews, showAllReviews]
  );

  if (reviews?.length === 0)
    return (
      <div className={css.noReviews}>
        {intl.formatMessage({ id: 'ListingPage.noReviewsMessage' })}
      </div>
    );

  return (
    <div className={css.root}>
      <ul className={classes}>
        {displayedReviews.map((r, index) => {
          return (
            <li key={`Review_${r?.id?.uuid || index}`} className={css.reviewItem}>
              {r?.id?.uuid ? (
                <Review review={r} intl={intl} isLast={index + 1 === displayedReviews?.length} />
              ) : (
                <ExternalReview
                  review={r}
                  intl={intl}
                  isLast={index + 1 === displayedReviews?.length}
                />
              )}
            </li>
          );
        })}
      </ul>
      {reviews?.length > MAX_REVIEWS_SHOWN_INITIALLY && (
        <InlineTextButton
          className={css.showMoreBtn}
          type="button"
          onClick={() => setShowAllReviews(prev => !prev)}
        >
          {showAllReviews ? (
            <FormattedMessage id="ListingPage.showLess" />
          ) : (
            <FormattedMessage id="ListingPage.showMore" />
          )}
        </InlineTextButton>
      )}
    </div>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
